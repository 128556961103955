<template>
  <div class="row">
    <div class="col-12 col-lg-3 pl-2 pr-0">
      <div class="card d-flex flex-row card-opacity providers-container">
        <alert
          v-if="loadingProviders"
          class="mb-0 w-100 border-0 bg-transparent h-45 shadow-0"
        ></alert>
        <div class="providers-inner-container" v-if="providers.length">
          <router-link
            v-for="provider in providers"
            :key="provider.id"
            :to="
              user && (user.isAdmin || user.isManagingAdmin)
                ? `/providers/${provider.id}`
                : `providers`
            "
            v-tooltip="provider.name"
          >
            <img
              class="user-avatar-img mr-2 rounded-circle"
              :src="provider.profile_picture || profileImagePlaceholder"
            />
          </router-link>
        </div>
        <router-link
          :to="`/clients/${patient.id}/providers`"
          class="plus-providers"
          v-if="
            !loadingProviders && user && (user.isAdmin || user.isManagingAdmin)
          "
          v-tooltip="'View more assigned providers or assign'"
        >
          <div
            class="
              w-100
              h-100
              rounded-circle
              bg-white
              d-flex
              justify-content-center
              align-items-center
              text-dark
            "
          >
            <i class="fas fa-plus"></i>
          </div>
        </router-link>

        <alert
          v-if="!loadingProviders && providers.length === 0"
          :hideLoader="true"
          class="mb-0 w-100 border-0 bg-transparent h-45 shadow-0"
        >
          No Providers
        </alert>
      </div>

      <div class="col-12 col-md-6 col-lg-auto p-0 pr-md-4 pr-lg-0 mb-4">
        <div
          class="
            user-profile-container
            d-flex
            align-items-center
            justify-content-between
            p-3
            dashboard-card
            light-shadow
            bg-img-card
          "
        >
          <div class="user-profile-logos-container d-flex align-items-center">
            <img
              :src="
                patient.profile_picture != null
                  ? patient.profile_picture.split('?response-content')[0]
                  : profileImagePlaceholder
              "
              class="user-picture"
            />
          </div>
          <div
            class="
              user-profile-info
              mb-auto
              col-6
              d-flex
              flex-column
              justify-content-between
            "
          >
            <div class="user-profile-name text-main" v-if="user">
              {{ patient.name }}
            </div>

            <div class="user-profile-role text-main">
              <span class="user-profile-gender-name">
                {{ patientGender || "Other" }}, {{ patientAge }} year{{
                  patientAge > 1 ? "s" : ""
                }}
              </span>
              <p class="user-profile-status ellipsis mt-2 mb-0">
                {{ patient.user.status }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Diagnosis</h3>
            <div
              class="card-info"
              :class="!showMore.diagnosis && 'three-lines'"
            >
              {{ patient.diagnosis || "N/A" }}
            </div>
            <div
              class="text-left mt-auto"
              v-if="patient.diagnosis && patient.diagnosis.length > 41"
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                @click="viewMore('diagnosis')"
              >
                Show {{ showMore.diagnosis ? "less" : "more" }}.
              </button>
            </div>
          </div>
          <div class="d-flex align-items-end justify-content-center">
            <img class="card-icon" src="@/assets/img/patient/diagnosis.png" />
          </div>
        </div>

        <div
          v-if="patient.assessment_active"
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Assessments</h3>
            <div
              class="card-info"
              :class="!showMore.assessment && 'three-lines'"
            >
              {{ patient.assessment || "N/A" }}
            </div>
            <div
              class="text-left mt-auto"
              v-if="patient.assessment && patient.assessment.length > 41"
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                @click="viewMore('assessment')"
              >
                Show {{ showMore.assessment ? "less" : "more" }}.
              </button>
            </div>
          </div>
          <div class="d-flex align-items-end justify-content-center">
            <img class="card-icon" src="@/assets/img/patient/assessment.png" />
          </div>
        </div>
        <div
          v-if="patient.treatment_active"
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Treatment</h3>
            <div
              class="card-info"
              :class="!showMore.treatment && 'three-lines'"
            >
              {{ patient.treatment || "N/A" }}
            </div>
            <div
              class="text-left mt-auto"
              v-if="patient.treatment && patient.treatment.length > 41"
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                @click="viewMore('treatment')"
              >
                Show {{ showMore.treatment ? "less" : "more" }}.
              </button>
            </div>
          </div>
          <div class="d-flex align-items-end justify-content-center">
            <img class="card-icon" src="@/assets/img/patient/medicine.png" />
          </div>
        </div>
        <div
          v-if="patient.intervention_active"
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Intervention/Plan</h3>
            <div
              class="card-info"
              :class="!showMore.intervention && 'three-lines'"
            >
              {{ patient.intervention || "N/A" }}
            </div>
            <div
              class="text-left mt-auto"
              v-if="patient.intervention && patient.intervention.length > 41"
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                @click="viewMore('intervention')"
              >
                Show {{ showMore.intervention ? "less" : "more" }}.
              </button>
            </div>
          </div>
          <div class="d-flex align-items-end justify-content-center">
            <img
              class="card-icon"
              src="@/assets/img/patient/intervention.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div class="col-12 mb-3 pb-4 flex-shrink-1">
        <ul
          class="
            nav nav-tabs
            header-tabs
            noPrint
            profile-nav-container
            patient-nav-container
          "
        >
          <li
            class="nav-item mr-1 mb-2"
            :class="{
              'u-display-none': link.patientHide && (!user || user.isPatient),
            }"
            v-for="link in nav"
            :key="link.to || link.action"
          >
            <router-link
              v-if="
                (!link.patientHide || !user || !user.isPatient) && !link.action
              "
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
            <a
              v-if="
                (!link.patientHide || !user || !user.isPatient) && link.action
              "
              href="/"
              @click="openCalendarModal"
              class="nav-link"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 card theme-card" :class="currentViewName">
        <keep-alive>
          <router-view class="pt-4"></router-view>
        </keep-alive>
      </div>
      <sidebar
        :toggle="modalOpened"
        @close="closeCalendarModal"
        :title="`${patient.name} Events`"
        wrapClass="calendar-tray"
        class="calendar-modal"
      >
        <Calendar
          :key="Math.random()"
          :profilePatientUserId="patient.user_id"
          :profileName="patient.name"
          @closeModal="modalOpened = false"
          v-if="modalOpened"
        ></Calendar>
      </sidebar>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Calendar from "../appointment/calendar";
import Alert from "@/components/Alert.vue";

export default {
  components: {
    Alert,
    Calendar,
  },
  data() {
    return {
      loadingProviders: true,
      providers: [],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      modalOpened: false,
      showMore: {
        diagnosis: false,
        assessment: false,
      },
      nav: [
        {
          label: "Dashboard",
          to: "patients.dashboard",
          icon: "chart-line",
        },
        {
          label: "General",
          to: "patients.basic",
          icon: "home",
        },
        {
          label: "Classes",
          to: "patients.classes",
          icon: "tags",
          patientHide: true,
        },
        {
          label: "Address",
          to: "patients.address",
          icon: "map-signs",
        },
        {
          label: "Phones",
          to: "patients.phones",
          icon: "phone",
        },
        {
          label: "Emails",
          to: "patients.emails",
          icon: "at",
        },
        {
          label: "Services",
          to: "patients.services",
          icon: "cogs",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
    }),
    currentViewName() {
      return this.$route.name.replace(".", "-"); // Access the current route's name
    },
    patientAge() {
      if (this.patient && this.patient.user) {
        const today = new Date();
        const birthDate = new Date(this.patient.user.birth_date);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age;
      }
      return "";
    },
    patientGender() {
      if (this.patient && this.patient.user) {
        const genderLabels = ["Other", "Male", "Female"];
        return genderLabels[this.patient.user.gender];
      }
      return "N/A";
    },
  },
  methods: {
    ...mapActions({
      getClientProviders: "patients/providers/getClientProviders",
    }),
    viewMore(type) {
      this.showMore[type] = !this.showMore[type];
      this.$forceUpdate();
    },
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.$route.params.id,
        },
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
  mounted() {
    this.getClientProviders(this.patient.id).then((data) => {
      if (data) {
        this.providers = data;
      }
      this.loadingProviders = false;
    });
  },
};
</script>

<style scoped lang="scss">
.patients-dashboard {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  margin-top: -24px;
}
.user-avatar-img,
.plus-providers {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.plus-providers {
  position: absolute;
  right: 0;
}
.providers-container {
  position: relative;
  padding-right: 50px;
  margin-bottom: 16px;
  .providers-inner-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(51px, 51px));
    grid-auto-flow: row;
    justify-content: start;
    height: 45px;
    overflow: hidden;
  }
}

.patient-nav-container {
  .nav-link {
    padding: 10px 16px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 99px;
    color: black;

    &.active {
      background-color: black;
      color: white;
      border: 0;
    }
  }
}

.dashboard-card {
  background-color: #ffffff;
  border-radius: 36px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
}

.info-card {
  min-height: 136px;
  padding: 16px 12px 16px 24px;
  margin-top: 4px;

  .info-container {
    width: 100%;
    padding-right: 4px;
  }

  .card-icon {
    max-width: 60px;
    max-height: 100%;
  }

  .card-info {
    margin-top: 12px;
    font-size: 15px;
  }
}

.user-profile-container {
  min-height: 280px;
  padding-bottom: 50px !important;
  height: auto;
}

.user-profile-info {
  background-color: rgba(235, 236, 235, 0.5);
  border-radius: 36px;
  min-height: 170px;
  padding: 12px;
}

.user-profile-logos-container {
  width: 60%;
  padding: 0 8px 0 0;
  .user-picture {
    width: 100%;
    max-height: 100%;
    border-radius: 36px;
    min-height: 210px;
    object-position: center;
    object-fit: cover;
  }
}

.bg-img-card {
  background-image: var(--profile-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 50%;
}
.user-profile-name {
  padding: 8px;
  font-size: 23px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-role {
  margin-top: 12px;
}

.user-profile-gender-name,
.user-profile-status {
  border-radius: 99px;
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--main-text-color);
  padding: 4px 10px;
  font-size: 14px;
  font-weight: normal;
  height: 29px;
}
</style>
